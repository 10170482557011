import * as React from "react";

import { Home } from "./pages/Home"
import { Error } from "./pages/Error"

import Layout from "./layouts/SinglePageLayout"
import { PrivacyPolicy } from "./pages/PrivacyPolicy";
import NoMatch from "./pages/NoMatch";
export const routes = [

  {
    path: "/",
    element: <Layout />,
    children: [
      { index: true, element: <Home /> },
      { path: "/privacy-policy", element: <PrivacyPolicy /> },
      { path: "*", element: <NoMatch /> }
    ]
  },
  // {
  //   path: "/",
  //   element: <Layout />,
  //   children: [
  //     { index: true, element: <Home />, errorElement: <Error /> }
  //   ],
  // },
  // {
  //   path: "/privacy-policy",
  //   element: <Layout />,
  //   children: [
  //     {
  //       index: true, element: <PrivacyPolicy />, errorElement: <Error />
  //     },

  //   ]
  // },
  // {
  //   path: "*", element: <Layout />,
  //   children: [
  //     {
  //       element: <NoMatch />
  //     }
  //   ]
  // }
]


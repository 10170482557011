import * as React from "react";
import PropTypes from "prop-types";
import { Card, Paper, CardContent, CardHeader, CardMedia, Grid, Typography, CardActionArea } from "@mui/material";
import FeatureItem from "./FeatureItem";

// Data 
import { features } from "../data/features";

function FeatureItemNew({ feature }) {
  return (
    // <Card sx={{ minWidth: 275 }} sm={6} elevation={6}>
    //   <CardHeader
    //     title={feature.imageLabel}
    //   >
    //   </CardHeader>
    //   <CardMedia
    //     component="img"
    //     width={160}
    //     image={feature.image}
    //     alt={feature.imageLabel}
    //   />
    //   <CardContent>
    //     <Typography variant="body2">
    //       {feature.description}
    //     </Typography>
    //   </CardContent>
    // </Card>
    // <Paper xs={12} sm={6}>

    <Grid container item xs={12} sm={6} >
      <Grid item >
        <CardActionArea component="a" href="#">
          <Card sx={{ display: 'flex', height: "100%" }} elevation="8" color="primary.main" borderRadius={4} >
            <CardContent sx={{ flex: 1 }}>
              <Typography component="h2" variant="h5" gutterBottom>
                {feature.title}
              </Typography>
              <CardMedia
                component="img"
                sx={{ width: 160, display: { xs: 'none', sm: 'block' } }}
                image={feature.image}
                alt={feature.imageLabel}
              />
              <Typography variant="subtitle1" color="text.secondary">
                {feature.date}
              </Typography>
              <Typography variant="subtitle2" paragraph>
                {feature.description}
              </Typography>
            </CardContent>
          </Card>
        </CardActionArea>
      </Grid>

    </Grid >
    // </Paper>
  )
}

function FeatureList() {
  return (
    <React.Fragment>
      <Grid container spacing={4} justify="space-between" alignItems="stretch">
        {features.map((feature, idx) => (
          <FeatureItemNew key={`${feature.title}.${idx}`} feature={feature} />
        ))}
      </Grid>
    </React.Fragment>
  )
}

export default FeatureList;



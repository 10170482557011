import * as React from "react";
import PropTypes from "prop-types";

import {
  Box,
  Grid,
  Typography,
  Link,
  Paper,
  Container,
  Card,
  CardMedia,
  CardContent,
  CardActionArea,
} from "@mui/material";
import { Download } from "@mui/icons-material";

const appInfoList = [
  {
    title: "iOS",
    image: "/apple-logo.svg",
    imageLabel: "Apple Logo",
    url: "https://testflight.apple.com/join/nqjB3RV8",
  },
  {
    title: "Android",
    image: "/android-logo.svg",
    imageLabel: "Android Logo",
    url: "https://play.google.com/apps/internaltest/4701654724701712345",
  },
];

function AppDownload({ app }) {
  return (
    <Grid container item xs={12} sm={6}>
      <CardActionArea component="a" href={app.url}>
        <Card
          sx={{ display: "flex", height: "100%" }}
          elevation="8"
          color="primary.main"
          borderRadius={4}
        >
          <CardContent sx={{ flex: 1 }}>
            <Typography component="h2" variant="h5" gutterBottom>
              {app.title}
            </Typography>
            <CardMedia
              component="img"
              sx={{ width: 160, display: { xs: "none", sm: "block" } }}
              image={app.image}
              alt={app.imageLabel}
            />
          </CardContent>
        </Card>
      </CardActionArea>
    </Grid>
  );
}

function DownloadAppsList() {
  return (
    <React.Fragment>
      <Typography
        component="h1"
        variant="h3"
        align="center"
        gutterBottom
        style={{ paddingBottom: "10px" }}
      >
        Help Test Our App (Coming Soon)
      </Typography>
      <Grid container spacing={4} justify="space-between" alignItems="stretch">
        {appInfoList.map((app, idx) => (
          <AppDownload key={`${app.title}.${idx}`} app={app} />
        ))}
      </Grid>
    </React.Fragment>
  );
}

export default DownloadAppsList;

import * as React from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Markdown from '../../components/Markdown';
import Container from "@mui/material/Container";



function FeatureList() {
  return (
    <Grid container spacing={2} justify="space-between" alignItems="stretch">

    </Grid>
  )
}

function Main(props) {
  const { children } = props;

  return (
    <React.Fragment>
      <main>
        {/* <Container> */}
        <Grid
          item
          xs={12}
          md={8}
          sx={{
            '& .markdown': {
              py: 3,
            },
          }}
        >
          {/* <Typography variant="h6" gutterBottom>
        {title}
      </Typography>
      <Divider />
      {posts.map((post) => (
        <Markdown className="markdown" key={post.substring(0, 40)}>
          {post}
        </Markdown>
      ))} */}
          {children ? children : null}
        </Grid>
        {/* </Container> */}
      </main>
    </React.Fragment>
  );
}

Main.propTypes = {
  // posts: PropTypes.arrayOf(PropTypes.string).isRequired,
  // title: PropTypes.string.isRequired,
  children: PropTypes.node
};

export default Main;
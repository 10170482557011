import * as React from "react";
import { Link } from "react-router-dom"
import { Container } from "@mui/material";
function NoMatch() {
  return (
    <React.Fragment>
      <Container maxWidth="lg">
        <h2>It looks like you're lost...</h2>
        <p>
          <Link to="/">Go to the home page</Link>
        </p>
      </Container>
    </React.Fragment>
  );
}
export default NoMatch;
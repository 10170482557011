import * as React from 'react';
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import { routes } from "./routes"
import customTheme from "./theme";
import { Error } from './pages/Error';


const theme = createTheme(customTheme);
const router = createBrowserRouter(routes)
function App() {
  return (
    <ThemeProvider theme={theme}>
      <RouterProvider router={router} errorElement={Error}>
        <CssBaseline />
      </RouterProvider>
    </ThemeProvider>
  );
}

export default App;

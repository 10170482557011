import { Container } from "@mui/system";
import * as React from "react";
import { useRouteError } from "react-router-dom";
import Header from "../../layouts/components/Header";
import Sidebar from "../../components/Sidebar"
import MainFeaturedPost from "../Home/components/Banner";
import FeaturedPost from "../Home/components/FeatureItem";
import Footer from "../../layouts/components/Footer";
import { Grid } from "@mui/material";
import { sections } from "../../data/sections";
function ErrorPage() {
  const error = useRouteError();
  return (
    <React.Fragment>
      <Container maxWidth="lg">
        <Grid container maxWidth="lg" spacing={5} sx={{ mt: 3 }}>
          <Grid item>
            <h1>Oops!</h1>
          </Grid>
          <Grid item>
            <h2>Sorry an unexpected error has occurred.</h2>
          </Grid>


          <p>
            <i>{error.statusText || error.message}</i>
          </p>

        </Grid>
      </Container>
    </React.Fragment>
  );

}
export default ErrorPage;
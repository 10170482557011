import * as React from 'react';
import PropTypes from 'prop-types';
import { Toolbar, AppBar, useScrollTrigger, Slide, Grid } from '@mui/material';
import Box from "@mui/material/Box"
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
// import { ReactComponent as ToolbarLogo } from '../surety-financial-logo-294x105.svg';
// import { ReactComponent as ToolbarLogo } from '../../surety-financial-horizontal.svg';
import { ReactComponent as ToolbarLogo } from "../assets/logo-horizontal.svg";
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import { Stack } from '@mui/system';
import styled from '@emotion/styled';


// function HideOnScroll(props) {
//   const { children, window } = props;
//   // Note that you normally won't need to set the window ref as useScrollTrigger
//   // will default to window.
//   // This is only being set here because the demo is in an iframe.
//   const trigger = useScrollTrigger({
//     target: window ? window() : undefined,
//   });

//   return (
//     <Slide appear={false} direction="down" in={!trigger}>
//       {children}
//     </Slide>
//   );
// }



function Header(props) {
  const { sections, title = "" } = props;

  return (
    <React.Fragment>
      <Toolbar>
        <ToolbarLogo style={{ maxHeight: "64px", padding: "8px" }} />
      </Toolbar>
    </React.Fragment>
  );
}

Header.propTypes = {
  sections: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    }),
  ).isRequired,
  title: PropTypes.string.isRequired,
};

export default Header;
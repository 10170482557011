import * as React from "react";
import { Container } from "@mui/material";
import Markdown from "../../components/Markdown";



function PrivacyPolicy() {
  const fileName = "privacy-policy.md";
  const [markdown, setMarkdown] = React.useState("");
  React.useEffect(() => {
    import(`./data/${fileName}`)
      .then(res => {
        fetch(res.default)
          .then(res => res.text())
          .then(res => setMarkdown(res))
          .catch(err => console.log(err));
      })
      .catch(err => console.log(err));
  })
  return (
    <Container maxWidth="lg">


      <Markdown className="markdown">
        {markdown}
      </Markdown>
    </Container>
  )
}

export default PrivacyPolicy;